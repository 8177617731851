import { endOfMonth, endOfWeek, startOfMonth, startOfWeek } from 'date-fns'
import * as React from 'react'
import { CalendarModeEnum, IDate, IDateCheckout, ITravelDate } from '@interfaces/data'
import { useAppContext } from '../AppContext'
import { getWeeks, useCalendarControls, useLocale } from './helpers'

type CalendarDaysProps = {
  activeDate: Date
  checkouts: IDateCheckout[]
  data: IDate[]
  isFetching: boolean
  mode: CalendarModeEnum
  onSelectDate: (date: Date) => void
  travelDate: ITravelDate
}

const CalendarDays: React.FunctionComponent<CalendarDaysProps> = ({
  activeDate,
  checkouts = [],
  data = [],
  isFetching = false,
  mode,
  onSelectDate,
  travelDate,
}) => {
  const locale = useLocale()
  const { currency, language } = useAppContext()
  const { onClickAway } = useCalendarControls()
  const startOfTheSelectedMonth = startOfMonth(activeDate)
  const endOfTheSelectedMonth = endOfMonth(activeDate)
  const startDate = startOfWeek(startOfTheSelectedMonth, { locale })
  const endDate = endOfWeek(endOfTheSelectedMonth, { locale })

  return (
    <div className="weekContainer">
      {getWeeks(
        startDate,
        endDate,
        data,
        checkouts,
        mode,
        travelDate,
        activeDate,
        onSelectDate,
        onClickAway,
        isFetching,
        currency,
        language
      )}
    </div>
  )
}

export default CalendarDays
