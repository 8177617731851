import { differenceInDays } from 'date-fns'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { IPrice, ITravelDate } from '@interfaces/data'
import { getPrice } from '../../helpers'
import { useAppContext } from '../AppContext'
import FormattedPrice from '../FormattedPrice'
import Skeleton from '../Skeleton'
import Typography from '../Typography'

type PriceProps = {
  isFinalPrice?: boolean
  lowestPrice?: IPrice | null
  price?: IPrice | null
  travelDate: ITravelDate
}

const Price: React.FunctionComponent<PriceProps> = ({
  isFinalPrice,
  lowestPrice,
  price,
  travelDate,
}) => {
  const { language } = useAppContext()
  return (
    <div className="italic flex flex-col items-end pr-8">
      <Typography className="text-dark font-medium" variant="subcategory">
        {isFinalPrice ? (
          price !== null ? (
            <FormattedPrice value={getPrice(price, 'amount', language)} />
          ) : (
            <Skeleton height={24} inline width={80} />
          )
        ) : (
          <>
            <span className="mr-1">
              <FormattedMessage
                defaultMessage="from"
                description="From price on Lowest price component"
              />
            </span>
            <FormattedPrice
              value={lowestPrice ? getPrice(lowestPrice, 'amount_per_night', language) : 0}
            />
          </>
        )}
      </Typography>
      <Typography className="text-muted" variant="small">
        {isFinalPrice && travelDate.from && travelDate.to ? (
          <FormattedMessage
            defaultMessage="{count, plural, one {for # night} other {for # nights}}"
            description="Count nights in Booking details"
            values={{ count: differenceInDays(travelDate.to, travelDate.from) }}
          />
        ) : (
          <FormattedMessage
            defaultMessage="/ night"
            description="night unit in Lowest price component"
          />
        )}
      </Typography>
    </div>
  )
}

export default Price
