import * as React from 'react'
import { FormattedNumber } from 'react-intl'
import { useAppContext } from './AppContext'

type FormattedPriceProps = {
  value: number
}

const FormattedPrice: React.FunctionComponent<FormattedPriceProps> = ({ value }) => {
  const { currency } = useAppContext()
  return (
    <FormattedNumber currency={currency} maximumFractionDigits={0} style="currency" value={value} />
  )
}

export default FormattedPrice
