import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { IPrice } from '@interfaces/data'
import { getPrice } from '../../helpers'
import { useAppContext } from '../AppContext'
import FormattedPrice from '../FormattedPrice'
import Typography from '../Typography'
import styles from './LowestPrice.module.scss'

type LowestPriceProps = {
  data: IPrice | null
}

const LowestPrice: React.FunctionComponent<LowestPriceProps> = ({ data }) => {
  const { language } = useAppContext()
  return (
    <div className={styles.root}>
      <Typography className={styles.rootPrice} component="div" variant="category">
        <span className="mr-2">
          <FormattedMessage
            defaultMessage="from"
            description="From price on Lowest price component"
          />
        </span>
        <FormattedPrice value={data ? getPrice(data, 'amount_per_night', language) : 0} />
      </Typography>
      <Typography className={styles.rootDescription} component="span">
        <FormattedMessage
          defaultMessage="night"
          description="night unit in Lowest price component"
        />
      </Typography>
    </div>
  )
}

export default LowestPrice
