import * as React from 'react'
import { IRoomType } from '@interfaces/data'
import { getLang } from '../../helpers'
import AccordionTitle from '../AccordionTitle'
import { useAppContext } from '../AppContext'

export type RoomTypeProps = {
  roomType: IRoomType
}

const RoomTypeName: React.FunctionComponent<RoomTypeProps> = ({ roomType }) => {
  const { language } = useAppContext()
  return <AccordionTitle>{getLang(roomType.category, language)}</AccordionTitle>
}

export default RoomTypeName
