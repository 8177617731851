import * as React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAppContext } from '@components/AppContext'
import CalendarAccordion from '@components/CalendarAccordion'
import Claim from '@components/Claim'
import DestinationAccordion from '@components/DestinationAccordion'
import GuestAccordion from '@components/GuestAccordion'
import HotelAccordion from '@components/HotelAccordion'
import HotelDialog from '@components/HotelDialog'
import PriceInfo from '@components/PriceInfo'
import RoomTypeAccordion from '@components/RoomTypeAccordion'
import RoomTypeDialog from '@components/RoomTypeDialog'
import ScrollToWrapper from '@components/ScrollToWrapper'
import VisitTypeCategoriesAccordion from '@components/VisitTypeCategoriesAccordion'
import VisitTypeDialog from '@components/VisitTypeDialog'
import {
  changeAccordion,
  changeValidationState,
  loadHotels,
  loadInitData,
  loadVisitCategories,
  setAppInitializedAndValidated,
  setStep,
} from '@redux/actions'
import * as selectors from '@redux/selectors'
import { getDefaultDestinationId, getDestinationById } from '@utils/destination'
import { getLang } from '../helpers'
import { useAccordion } from '../hooks/useAccordion'
import { useCalendarAccordion } from '../hooks/useCalendarAccordion'
import { useDestinationsAccordion } from '../hooks/useDestinationsAccordion'
import { useGuestsAccordion } from '../hooks/useGuestsAccordion'
import { useHotelAccordion } from '../hooks/useHotelAccordion'
import { DESTINATION_PARAM, useQueryParams } from '../hooks/useQueryParams'
import { useRoomTypeAccordion } from '../hooks/useRoomTypeAccordion'
import { useVisitTypeCategoriesAccordion } from '../hooks/useVisitTypeCategoriesAccordion'

const Home: React.FunctionComponent = () => {
  const { language } = useAppContext()
  const dispatch = useDispatch()
  // load query params for the first time when the page is mounted
  const { addQueryParameter, routerQueryParams } = useQueryParams()

  const defaultDestination = getDefaultDestinationId()

  const { resolveAccordionFromRouteQuery } = useAccordion()
  const { onInitGuestValidation } = useGuestsAccordion()
  const { onInitDestinationValidation } = useDestinationsAccordion()
  const { onInitHotelValidation } = useHotelAccordion()
  const { onInitVisitTypeValidation } = useVisitTypeCategoriesAccordion()
  const { onInitRoomTypeValidation } = useRoomTypeAccordion()
  useCalendarAccordion()

  const validationState = useSelector(selectors.getValidationState)
  const guest = useSelector(selectors.getGuest)
  const roomType = useSelector(selectors.getRoomType)
  const hotel = useSelector(selectors.getHotel)
  const visitType = useSelector(selectors.getVisitType)
  const destination = useSelector(selectors.getDestination)
  const destinations = useSelector(selectors.getDestinations)
  const accordion = useSelector(selectors.getAccordion)
  const appInitialized = useSelector(selectors.getAppInitialized)
  const appInitializedAndValidated = useSelector(selectors.getAppInitializedAndValidated)
  const languageInitialized = useSelector(selectors.getLanguageInitialized)

  React.useEffect(() => {
    dispatch(setStep(1))

    if (!routerQueryParams?.destination) {
      const defaultDestination = getDefaultDestinationId()

      if (defaultDestination && destinations) {
        const destinationById = getDestinationById(defaultDestination, destinations)

        if (destinationById) {
          const destinationName = getLang(destinationById.name, language)
          addQueryParameter(DESTINATION_PARAM, destinationName)
        }
      }
    }
  }, [dispatch])

  useEffect(() => {
    if (!appInitialized && !appInitializedAndValidated && languageInitialized) {
      dispatch(loadInitData(routerQueryParams))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appInitialized, appInitializedAndValidated, languageInitialized, dispatch])

  useEffect(() => {
    // when destinations are loaded
    if (!!destinations) {
      if (defaultDestination) {
        const destinationById = getDestinationById(defaultDestination, destinations)
        if (destinationById) {
          addQueryParameter(DESTINATION_PARAM, getLang(destinationById.name, language))
        }
      }
      dispatch(changeValidationState('DESTINATIONS'))
    }
  }, [destinations, defaultDestination])

  useEffect(() => {
    if (appInitialized && languageInitialized && !appInitializedAndValidated) {
      switch (validationState) {
        case 'DESTINATIONS': {
          onInitDestinationValidation(destination)
          dispatch(changeValidationState('GUESTS'))
          break
        }
        case 'GUESTS': {
          onInitGuestValidation(guest)
          dispatch(changeValidationState('VISIT_TYPE'))
          break
        }
        case 'VISIT_TYPE': {
          onInitVisitTypeValidation(visitType)
          dispatch(changeValidationState('HOTEL'))
          break
        }
        case 'HOTEL': {
          onInitHotelValidation(hotel)
          dispatch(changeValidationState('ROOM_TYPE'))
          break
        }
        case 'ROOM_TYPE': {
          onInitRoomTypeValidation(roomType)
          dispatch(changeValidationState(null))
          break
        }
        case null: {
          dispatch(setAppInitializedAndValidated(true))
        }
        default:
      }
    }
  }, [
    dispatch,
    appInitialized,
    appInitializedAndValidated,
    languageInitialized,
    guest,
    destination,
    validationState,
    visitType,
    hotel,
    roomType,
  ])

  useEffect(() => {
    if (appInitialized) {
      dispatch(
        loadVisitCategories({
          destination_id: destination?.id || null,
        })
      )
    }
  }, [appInitialized, dispatch, destination?.id])

  useEffect(() => {
    dispatch(
      loadHotels({ destination_id: visitType?.id || null, visit_type_id: visitType?.id || null })
    )
  }, [dispatch, visitType?.id, destination?.id])

  useEffect(() => {
    if (appInitialized && languageInitialized && appInitializedAndValidated) {
      const accordion = resolveAccordionFromRouteQuery()
      dispatch(changeAccordion(accordion))
    }
  }, [
    appInitialized,
    languageInitialized,
    routerQueryParams.guest,
    routerQueryParams.destination,
    routerQueryParams.visitType,
    routerQueryParams.hotel,
    routerQueryParams.roomType,
    routerQueryParams.visitStart && routerQueryParams.visitEnd,
    appInitializedAndValidated,
  ])

  const selectedAccordionId = accordion?.toString() || '0'

  return (
    <>
      <Claim />
      <div className="my-6 md:mt-12 md:mb-0">
        <div
          style={{
            ...(defaultDestination && { display: 'none' }),
          }}
        >
          <ScrollToWrapper
            element={<DestinationAccordion />}
            id="0"
            selectedId={selectedAccordionId}
          />
        </div>
        <ScrollToWrapper element={<GuestAccordion />} id="1" selectedId={selectedAccordionId} />
        <ScrollToWrapper
          element={<VisitTypeCategoriesAccordion />}
          id="2"
          selectedId={selectedAccordionId}
        />
        <ScrollToWrapper element={<HotelAccordion />} id="3" selectedId={selectedAccordionId} />
        <ScrollToWrapper element={<RoomTypeAccordion />} id="4" selectedId={selectedAccordionId} />
        <ScrollToWrapper element={<CalendarAccordion />} id="5" selectedId={selectedAccordionId} />
      </div>
      <PriceInfo />
      <VisitTypeDialog />
      <HotelDialog />
      <RoomTypeDialog />
    </>
  )
}

export default Home
