import clsx from 'classnames'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useAppContext } from '@components/AppContext'
import FormattedPrice from '@components/FormattedPrice'
import styles from '@components/PriceInfo/Price.module.scss'
import Typography from '@components/Typography'
import { IPrice, IPriceBreakdownDiscount } from '@interfaces/data'
import {
  getAllDiscounts,
  getBasePrice,
  getKeyLang,
  getPrice,
  getTotalDiscountedPrice,
} from '../../helpers'

interface PriceBreakdownProps {
  data: IPrice | null
}

export const PriceBreakdown = ({ data }: PriceBreakdownProps) => {
  const { language } = useAppContext()
  const totalDiscountedPrice = data ? getTotalDiscountedPrice(data, language) : 0

  return (
    <div className={styles.rootPriceBreakDown}>
      <div className="w-full flex flex-col gap-2 py-2">
        <div className={clsx(styles.rootPriceBreakDownRow)}>
          <Typography className="text-muted">
            <FormattedMessage
              defaultMessage="Base Price"
              description="Label for base price summary"
            />
            :
          </Typography>
          <div className="font-medium">
            <FormattedPrice value={data ? getBasePrice(data, language) : 0} />
          </div>
        </div>
        <div className={styles.rootPriceDiscountBreakDown}>
          <div className={styles.rootPriceBreakDownRow}>
            <Typography className="text-muted">
              <FormattedMessage
                defaultMessage="Discounts"
                description="Label for loyalty programs summary"
              />
              :
            </Typography>
            <div className="font-medium">
              <FormattedPrice value={totalDiscountedPrice} />
            </div>
          </div>
          {totalDiscountedPrice < 0 && (
            <>
              {getAllDiscounts(data, language).map((discount: IPriceBreakdownDiscount) => {
                const {
                  guest,
                  loyalty_program: { id: loyaltyProgramId, name },
                  price_discount,
                } = discount

                return (
                  <div
                    className={clsx(
                      styles.rootPriceBreakDownRow,
                      styles.rootPriceBreakDownDiscountRow
                    )}
                    key={loyaltyProgramId}
                  >
                    <Typography
                      className="font-normal text-muted uppercase"
                      component="span"
                      variant="extra-small"
                    >
                      {name[getKeyLang(language)]}
                      {' - '}
                      <FormattedMessage
                        defaultMessage="{count}st Guest"
                        description="Count guest in guests"
                        values={{ count: guest }}
                      />
                    </Typography>
                    <Typography
                      className="font-normal text-muted"
                      component="span"
                      variant="extra-small"
                    >
                      <FormattedPrice value={-price_discount} />
                    </Typography>
                  </div>
                )
              })}
            </>
          )}
        </div>
      </div>
      <div className={clsx(styles.rootPriceBreakDownRow, 'mt-5')}>
        <Typography className={styles.rootPrice} component="span" variant="category">
          <FormattedMessage defaultMessage="Total" description="Label for total price summary" />
        </Typography>
        <div className="flex flex-col items-end">
          <Typography className={clsx(styles.rootPrice)} component="span" variant="category">
            <FormattedPrice value={data ? getPrice(data, 'amount', language) : 0} />
          </Typography>
          <Typography className={clsx(styles.rootDescription, 'text-muted')} component="span">
            <FormattedMessage defaultMessage="(incl. VAT)" description="Text in Price component" />
          </Typography>
        </div>
      </div>
    </div>
  )
}
