import { useDispatch } from 'react-redux'
import { useAppContext } from '@components/AppContext'
import { IRoomType } from '@interfaces/data'
import { changeRoomType } from '@redux/actions'
import { getKeyLang } from '../helpers'
import { ROOM_TYPE_PARAM, useQueryParams } from './useQueryParams'

export const useRoomTypeAccordion = () => {
  const { language } = useAppContext()
  const dispatch = useDispatch()
  const { addQueryParameter, removeQueryParameter } = useQueryParams()

  const updateRouteQuery = (value: string | null | undefined) => {
    if (value) {
      addQueryParameter(ROOM_TYPE_PARAM, value)
    } else {
      removeQueryParameter(ROOM_TYPE_PARAM)
    }
  }

  const validateRoomType = (value: IRoomType | null) => {
    const roomType = value?.category[getKeyLang(language)]
    updateRouteQuery(roomType)
  }

  const onChangeRoomType = (value: IRoomType | null) => {
    dispatch(changeRoomType(value))
    validateRoomType(value)
  }

  const onInitRoomTypeValidation = (value: IRoomType | null) => {
    validateRoomType(value)
  }

  return {
    onChangeRoomType,
    onInitRoomTypeValidation,
  }
}
