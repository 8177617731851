import clsx from 'classnames'
import * as React from 'react'
import { useSelector } from 'react-redux'
import Skeleton from '@components/Skeleton'
import * as selectors from '@redux/selectors'
import BookingDetails from '../BookingDetails'
import { useAllBookingParams } from './helpers'
import LowestPrice from './LowestPrice'
import Price from './Price'
import styles from './PriceInfo.module.scss'
import ReservationButtons from './ReservationButtons'

const PriceInfo: React.FunctionComponent = () => {
  const { isAllBookingParams, lowestPrice, onBookingRequest, onlyAvailableDemand, price } =
    useAllBookingParams()
  const appInitialized = useSelector(selectors.getAppInitialized)
  const datesFetching = useSelector(selectors.isDatesFetching)
  const fetching = useSelector(selectors.isFetching)

  const isDataFetching = datesFetching || fetching

  return (
    <div
      className={clsx(styles.root, {
        [styles.rootIsValid]: isAllBookingParams,
      })}
    >
      <div className={styles.rootWrapper}>
        {isDataFetching ? (
          <Skeleton containerClassName="flex items-center" height={30} width={200} />
        ) : (
          <>{isAllBookingParams ? <Price data={price} /> : <LowestPrice data={lowestPrice} />}</>
        )}
        <BookingDetails />
      </div>
      {appInitialized && (
        <ReservationButtons
          disabled={!isAllBookingParams}
          onBookingRequest={onBookingRequest}
          onlyAvailableDemand={onlyAvailableDemand}
        />
      )}
    </div>
  )
}

export default PriceInfo
