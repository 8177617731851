import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setRoomTypeDialogData } from '@redux/actions'
import * as selectors from '@redux/selectors'
import { getLang } from '../helpers'
import { useAppContext } from './AppContext'
import Dialog from './Dialog'
import Markdown from './Markdown'

const RoomTypeDialog: React.FunctionComponent = () => {
  const { language } = useAppContext()
  const dispatch = useDispatch()
  const data = useSelector(selectors.getRoomTypeDialog)
  return (
    <Dialog
      onClose={() => dispatch(setRoomTypeDialogData(null))}
      open={Boolean(data)}
      title={getLang(data?.category, language) || ''}
    >
      <Markdown source={getLang(data?.description, language) || ''} />
    </Dialog>
  )
}

export default RoomTypeDialog
