import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { AccordionEnum } from '@interfaces/data'
import { changeAccordion } from '@redux/actions'
import { basicSelector } from '../../helpers'
import { useCalendarAccordion } from '../../hooks/useCalendarAccordion'
import { useQueryParams } from '../../hooks/useQueryParams'
import Accordion from '../Accordion'
import AccordionTitle from '../AccordionTitle'
import Calendar from './Calendar'
import TravelDateName from './TravelDateName'

const CalendarAccordion: React.FunctionComponent = () => {
  const dispatch = useDispatch()
  const { accordion, hotel, isFetching, roomType, travelDate, visitType } =
    useSelector(basicSelector)
  const { routerQueryParams } = useQueryParams()
  const { removeTravelDate } = useCalendarAccordion()

  const isLoading = isFetching && (!!routerQueryParams.visitStart || !!routerQueryParams.visitEnd)
  const isActive = isLoading || (!!travelDate?.from && !!travelDate.to)
  const isDisabled = !hotel || !roomType || !visitType

  return (
    <Accordion
      active={isActive}
      disabled={isDisabled}
      isLoading={isLoading}
      onClear={() => removeTravelDate()}
      onClose={() => dispatch(changeAccordion(null))}
      onOpen={() => dispatch(changeAccordion(AccordionEnum.CALENDAR))}
      open={accordion == AccordionEnum.CALENDAR}
      title={
        travelDate.from && travelDate.to ? (
          <TravelDateName travelDate={travelDate} />
        ) : (
          <AccordionTitle isLoading={isLoading}>
            <FormattedMessage defaultMessage="Date" description="Calendar accordion title" />
          </AccordionTitle>
        )
      }
    >
      <Calendar />
    </Accordion>
  )
}

export default CalendarAccordion
