import clsx from 'classnames'
import { Locale } from 'date-fns'
import * as React from 'react'
import { CalendarModeEnum, IDate, IDateCheckout, ITravelDate } from '@interfaces/data'
import CalendarDays from './CalendarDays'
import CalendarHeader from './CalendarHeader'
import CalendarWeekDaysNames from './CalendarWeekDaysNames'

type CalendarWrapperProps = {
  checkouts: IDateCheckout[]
  data: IDate[]
  date: Date
  isFetching: boolean
  locale: Locale
  mode: CalendarModeEnum
  onSelectDate: (date: Date) => void
  secondary?: boolean
  travelDate: ITravelDate
}

const CalendarWrapper: React.FunctionComponent<CalendarWrapperProps> = ({
  checkouts = [],
  data = [],
  date,
  isFetching = false,
  locale,
  mode,
  onSelectDate,
  secondary = false,
  travelDate,
}) => (
  <div className={clsx('wrapper', { secondary })}>
    <CalendarHeader date={date} locale={locale} />
    <CalendarWeekDaysNames date={date} locale={locale} />
    <CalendarDays
      activeDate={date}
      checkouts={checkouts}
      data={data}
      isFetching={isFetching}
      mode={mode}
      onSelectDate={onSelectDate}
      travelDate={travelDate}
    />
  </div>
)

export default CalendarWrapper
