import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { AccordionEnum, Identificator } from '@interfaces/data'
import { loadHotel } from '@redux/actions'
import { basicSelector } from '../../helpers'
import { useAccordion } from '../../hooks/useAccordion'
import { useHotelAccordion } from '../../hooks/useHotelAccordion'
import { useQueryParams } from '../../hooks/useQueryParams'
import Accordion from '../Accordion'
import AccordionTitle from '../AccordionTitle'
import HotelName from './HotelName'
import Hotels from './Hotels'

const HotelAccordion: React.FunctionComponent = () => {
  const dispatch = useDispatch()
  const { accordion, destination, hotel, hotels, isFetching, visitType } =
    useSelector(basicSelector)
  const { routerQueryParams } = useQueryParams()
  const { onChangeAccordion } = useAccordion()
  const { onChangeHotel } = useHotelAccordion()

  const onLoadHotel = (hotelId: Identificator) => dispatch(loadHotel(hotelId))

  const isLoading = !!routerQueryParams.hotel && isFetching
  const isActive = isLoading || !!hotel
  const isDisabled = !destination || !visitType

  return (
    <Accordion
      active={isActive}
      disabled={isDisabled}
      isLoading={isLoading}
      onClear={() => onChangeHotel(null)}
      onClose={() => onChangeAccordion(null)}
      onOpen={() => onChangeAccordion(AccordionEnum.HOTELS)}
      open={accordion === AccordionEnum.HOTELS}
      title={
        hotel?.name ? (
          <HotelName hotel={hotel} />
        ) : (
          <AccordionTitle isLoading={isLoading}>
            <FormattedMessage defaultMessage="Hotel" description="Hotel accordion title" />
          </AccordionTitle>
        )
      }
    >
      <Hotels
        hotels={hotels}
        onLoadHotel={onLoadHotel}
        onSelect={onChangeHotel}
        selectedHotel={hotel}
      />
    </Accordion>
  )
}

export default HotelAccordion
