import * as React from 'react'
import { getVisitTypesFilter } from '@components/VisitTypeCategoriesAccordion/helpers'
import { IVisitCategory, IVisitType } from '@interfaces/data'
import { useAppContext } from '../AppContext'
import VisitTypeCategory from './VisitTypeCategory'
import VisitTypeCategorySkeleton from './VisitTypeCategorySkeleton'

type VisitTypesCategoriesProps = {
  onLoadVisitType: (type: IVisitType) => void
  onSelectVisitType: (visitType: IVisitType) => void
  onSelectVisitTypeCategory: (visitType: IVisitCategory) => void
  selectVisitType?: IVisitType | null
  selectVisitTypeCategory?: IVisitCategory | null
  visitCategories: IVisitCategory[]
}

const VisitTypesCategories: React.FunctionComponent<VisitTypesCategoriesProps> = ({
  onLoadVisitType,
  onSelectVisitType,
  onSelectVisitTypeCategory,
  selectVisitType = null,
  selectVisitTypeCategory = null,
  visitCategories,
}) => {
  const { language } = useAppContext()
  const data = visitCategories
    .filter((visitCategories) => visitCategories.visitTypes.length > 0)
    .filter(
      (visitCategories) => getVisitTypesFilter(visitCategories.visitTypes, language).length > 0
    )
  return (
    <>
      {data.map((visitCategory) => (
        <VisitTypeCategory
          key={visitCategory.id}
          onLoadVisitType={onLoadVisitType}
          onSelectVisitType={onSelectVisitType}
          onSelectVisitTypeCategory={onSelectVisitTypeCategory}
          selectVisitType={selectVisitType}
          selectVisitTypeCategory={selectVisitTypeCategory}
          visitCategory={visitCategory}
        />
      ))}
      {data.length === 0 &&
        Array.from(Array(4).keys()).map((item) => <VisitTypeCategorySkeleton key={item} />)}
    </>
  )
}

export default VisitTypesCategories
