import clsx from 'classnames'
import * as React from 'react'
import { useBookingDetails } from '../helpers'
import styles from './BookingDetails.module.scss'
import Typography from './Typography'

const BookingDetails = () => {
  const { data } = useBookingDetails()
  return data.length > 0 ? (
    <div className={clsx(styles.root)}>
      {data.map((item, index) => (
        <Typography className={styles.rootItem} component="span" key={index} variant="caption">
          {item}
        </Typography>
      ))}
    </div>
  ) : null
}

export default BookingDetails
