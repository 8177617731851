import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { PriceBreakdown } from '@components/PriceInfo/PriceBreakdown'
import { IPrice } from '@interfaces/data'
import * as selectors from '@redux/selectors'
import { getPrice } from '../../helpers'
import { useAppContext } from '../AppContext'
import FormattedPrice from '../FormattedPrice'
import Typography from '../Typography'
import styles from './Price.module.scss'

type PriceProps = {
  data: IPrice | null
}

const Price: React.FunctionComponent<PriceProps> = ({ data }) => {
  const { language } = useAppContext()
  const step = useSelector(selectors.getStep)

  const showPriceBreakdown = step === 4

  return (
    <div className={styles.root}>
      {!showPriceBreakdown ? (
        <>
          <Typography className={styles.rootPrice} component="span" variant="category">
            <FormattedPrice value={data ? getPrice(data, 'amount', language) : 0} />
          </Typography>
          <Typography className={styles.rootDescription} component="span">
            <FormattedMessage defaultMessage="(incl. VAT)" description="Text in Price component" />
          </Typography>
        </>
      ) : (
        <PriceBreakdown data={data} />
      )}
    </div>
  )
}

export default Price
