import * as React from 'react'
import { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { AccordionEnum } from '@interfaces/data'
import { changeGuest } from '@redux/actions'
import * as selectors from '@redux/selectors'
import { getValidGuestNumberOrNull } from '../../helpers'
import { useAccordion } from '../../hooks/useAccordion'
import { useGuestsAccordion } from '../../hooks/useGuestsAccordion'
import { GUEST_PARAM, useQueryParams } from '../../hooks/useQueryParams'
import Accordion from '../Accordion'
import AccordionTitle from '../AccordionTitle'
import Guests from './Guests'

const selector = createSelector(
  selectors.getAccordion,
  selectors.getGuest,
  selectors.isFetching,
  (accordion, guest, isFetching) => ({
    accordion,
    guest,
    isFetching,
  })
)

const GuestAccordion: React.FunctionComponent = () => {
  const dispatch = useDispatch()
  const { addQueryParameter, routerQueryParams } = useQueryParams()
  const { accordion, guest, isFetching } = useSelector(selector)
  const appInitialized = useSelector(selectors.getAppInitialized)

  const isLoading = isFetching && !!routerQueryParams.guest
  const isActive = isLoading || !!guest

  const { onChangeGuest } = useGuestsAccordion()
  const { onChangeAccordion } = useAccordion()

  useEffect(() => {
    const guestFromQueryParameters = getValidGuestNumberOrNull(routerQueryParams.guest)

    if (appInitialized) {
      if (guest && !guestFromQueryParameters) {
        // Guests can be set during initialization even when the guest parameter is empty, but beds are filled.
        addQueryParameter(GUEST_PARAM, `${guest}`)
      } else if (!guest && guestFromQueryParameters !== null) {
        // Covers the situation where the booking form is loading with no guests selected, but a customer selects a guest in the meantime.
        dispatch(changeGuest(guestFromQueryParameters))
      }
    }
  }, [appInitialized, guest, routerQueryParams.guest])

  return (
    <Accordion
      active={isActive}
      isLoading={isLoading}
      onClear={() => onChangeGuest(null)}
      onClose={() => onChangeAccordion(null)}
      onOpen={() => onChangeAccordion(AccordionEnum.GUESTS)}
      open={accordion === AccordionEnum.GUESTS}
      title={
        <AccordionTitle isLoading={isLoading}>
          <FormattedMessage
            defaultMessage="{count, plural, =0 {Guests} one {# Guest} other {# Guests}}"
            description="Guest accordion title"
            values={{ count: parseInt(String(guest || 0)) }}
          />
        </AccordionTitle>
      }
    >
      <Guests onChange={onChangeGuest} value={guest} />
    </Accordion>
  )
}

export default GuestAccordion
