import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import Accordion from '@components/Accordion'
import AccordionTitle from '@components/AccordionTitle'
import Destinations from '@components/DestinationAccordion/Destinations'
import { AccordionEnum } from '@interfaces/data'
import * as selectors from '@redux/selectors'
import { useAccordion } from '../../hooks/useAccordion'
import { useDestinationsAccordion } from '../../hooks/useDestinationsAccordion'

const selector = createSelector(
  selectors.getAccordion,
  selectors.getDestination,
  selectors.getDestinations,
  selectors.isFetching,
  (accordion, destination, destinations, isFetching) => ({
    accordion,
    destination,
    destinations,
    isFetching,
  })
)

const DestinationAccordion = () => {
  const { onChangeAccordion } = useAccordion()
  const { onChangeDestination } = useDestinationsAccordion()

  const { accordion, destination, destinations, isFetching } = useSelector(selector)
  const isLoading = isFetching
  const isActive = isLoading || !!destination

  return (
    <Accordion
      active={isActive}
      isLoading={isLoading}
      onClear={() => {
        onChangeDestination(null)
      }}
      onClose={() => onChangeAccordion(null)}
      onOpen={() => onChangeAccordion(AccordionEnum.DESTINATION)}
      open={accordion === AccordionEnum.DESTINATION}
      title={
        <AccordionTitle isLoading={isLoading}>
          <FormattedMessage
            defaultMessage="Destinations"
            description="Destination accordion title"
          />
        </AccordionTitle>
      }
    >
      <Destinations
        destinations={destinations ?? []}
        onChange={onChangeDestination}
        value={destination}
      />
    </Accordion>
  )
}

export default DestinationAccordion
