import { format } from 'date-fns'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  GuestsFormData,
  IDestination,
  IHotel,
  IPrice,
  IRoomType,
  ITravelDate,
  IVisitType,
  LoyaltyProgramData,
  PersonalFormData,
} from '@interfaces/data'
import { loadLowestPrice, loadPrice, setDemand, setRouteQueryParams } from '@redux/actions'
import { DATE_FORMAT_YYYY_MM_DD } from '../../constants'
import { basicSelector, PathEnum, redirectTo, reportConversion } from '../../helpers'
import { useQueryParams } from '../../hooks/useQueryParams'

export type BookingRequest = (binding: boolean) => void

export type AllBookingParams = {
  destination: IDestination | null
  guest: number | null
  guestsData: GuestsFormData
  hotel: IHotel | null
  isAllBookingParams: boolean
  lowestPrice: IPrice | null
  onBookingRequest: BookingRequest
  onlyAvailableDemand: boolean
  personalData: PersonalFormData | null
  price: IPrice | null
  roomType: IRoomType | null
  travelDate: ITravelDate | null
  visitType: IVisitType | null
}

const getLoyaltyProgramsForDependencyArray = (loyaltyPrograms: LoyaltyProgramData[]) => {
  const programIds = loyaltyPrograms.map((program) => program.id)
  return programIds.join('-')
}

export const useAllBookingParams = (): AllBookingParams => {
  const {
    currency,
    destination,
    guest,
    guestsData,
    hotel,
    isAllBookingParams,
    language,
    lowestPrice,
    onlyAvailableDemand,
    personalData,
    price,
    roomType,
    serviceData,
    travelDate,
    visitType,
  } = useSelector(basicSelector)
  const dispatch = useDispatch()
  const { routerQueryParams } = useQueryParams()

  // The useEffect dependency array uses shallow comparison. Simply using serviceData.loyaltyPrograms as an array is insufficient; therefore, we need to stringify it.
  const loyaltyProgramsString = getLoyaltyProgramsForDependencyArray(serviceData.loyaltyPrograms)

  const onBookingRequest = (demand: boolean) => {
    if (onlyAvailableDemand) {
      dispatch(setDemand(true))
    } else {
      dispatch(setDemand(demand))
    }
    reportConversion('beginCheckout', language, price, currency, visitType, roomType, hotel)
    dispatch(setRouteQueryParams(routerQueryParams))
    redirectTo(PathEnum.STEP_2, {}, language)
  }

  React.useEffect(() => {
    const query = new URLSearchParams()

    if (guest && !roomType?.id) {
      query.append('number_of_beds', guest.toString())
    }
    if (visitType?.id) {
      query.append('visit_type_id', visitType.id.toString())
    }
    if (hotel?.id) {
      query.append('hotel_id', hotel.id.toString())
    }
    if (roomType?.id) {
      query.append('room_type_id', roomType.id.toString())
    }

    if (isAllBookingParams) {
      if (travelDate.from && travelDate.to) {
        query.append('date_from', format(travelDate.from, DATE_FORMAT_YYYY_MM_DD))
        query.append('date_to', format(travelDate.to, DATE_FORMAT_YYYY_MM_DD))
      }
      query.delete('hotel_id')
      query.delete('number_of_beds')

      for (let i = 0; guest && i < guest; i++) {
        const loyaltyProgramId = serviceData.loyaltyPrograms?.[i]?.id.toString() || false
        if (loyaltyProgramId) {
          query.append(`guest_${i + 1}_loyalty_program_id`, loyaltyProgramId)
        }
      }

      dispatch(loadPrice(query))
    } else {
      if (destination?.id) {
        query.append('destination_id', destination.id.toString())
      }

      dispatch(loadLowestPrice(query))
    }
  }, [
    guest,
    hotel,
    travelDate?.from,
    travelDate?.to,
    roomType?.id,
    visitType?.id,
    isAllBookingParams,
    dispatch,
    loyaltyProgramsString,
    destination?.id,
  ])

  return {
    destination,
    guest,
    guestsData,
    hotel,
    isAllBookingParams,
    lowestPrice,
    onBookingRequest,
    onlyAvailableDemand,
    personalData,
    price,
    roomType,
    travelDate,
    visitType,
  }
}
