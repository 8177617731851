import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { AccordionEnum, IRoomType } from '@interfaces/data'
import { setRoomTypeDialogData } from '@redux/actions'
import { basicSelector } from '../../helpers'
import { useAccordion } from '../../hooks/useAccordion'
import { useQueryParams } from '../../hooks/useQueryParams'
import { useRoomTypeAccordion } from '../../hooks/useRoomTypeAccordion'
import Accordion from '../Accordion'
import AccordionTitle from '../AccordionTitle'
import RoomTypeName from './RoomTypeName'
import RoomTypes from './RoomTypes'

const RoomTypeAccordion: React.FunctionComponent = () => {
  const dispatch = useDispatch()
  const { accordion, guest, hotel, isFetching, roomType } = useSelector(basicSelector)
  const { routerQueryParams } = useQueryParams()
  const { onChangeAccordion } = useAccordion()
  const { onChangeRoomType } = useRoomTypeAccordion()

  const onLoadRoomType = (value: IRoomType | null) => dispatch(setRoomTypeDialogData(value))

  const isLoading = !!routerQueryParams.roomType && isFetching
  const isActive = isLoading || !!roomType

  return (
    <Accordion
      active={isActive}
      disabled={!Boolean(hotel)}
      isLoading={isLoading}
      onClear={() => onChangeRoomType(null)}
      onClose={() => onChangeAccordion(null)}
      onOpen={() => onChangeAccordion(AccordionEnum.ROOMS)}
      open={accordion === AccordionEnum.ROOMS}
      title={
        roomType ? (
          <RoomTypeName roomType={roomType} />
        ) : (
          <AccordionTitle isLoading={isLoading}>
            <FormattedMessage
              defaultMessage="Room Category"
              description="Room category accordion title"
            />
          </AccordionTitle>
        )
      }
    >
      <RoomTypes
        hotel={hotel || null}
        numberBets={guest}
        onLoadRoomType={onLoadRoomType}
        onSelect={onChangeRoomType}
        selectedRoomType={roomType}
      />
    </Accordion>
  )
}

export default RoomTypeAccordion
