import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { IHotel } from '@interfaces/data'
import { changeHotel, changeRoomType } from '@redux/actions'
import * as selectors from '@redux/selectors'
import {
  HOTEL_PARAM,
  ROOM_TYPE_PARAM,
  useQueryParams,
  VISIT_END_PARAM,
  VISIT_START_PARAM,
} from './useQueryParams'

const selector = createSelector(
  selectors.getAccordion,
  selectors.getRoomType,
  selectors.getHotel,
  selectors.getGuest,
  (accordion, roomType, hotel, guest) => ({
    accordion,
    guest,
    hotel,
    roomType,
  })
)

export const useHotelAccordion = () => {
  const dispatch = useDispatch()
  const { guest, roomType } = useSelector(selector)
  const { addQueryParameter, removeQueryParameter, removeQueryParametersArray } = useQueryParams()

  const updateRouteQuery = (value: string | undefined) => {
    if (value) {
      addQueryParameter(HOTEL_PARAM, value)
    } else {
      removeQueryParameter(HOTEL_PARAM)
    }
  }

  const validateHotelRoomType = (currentHotelValue: IHotel | null) => {
    if (roomType) {
      // e.g. hotel has been changed, and we have to check if a room type is included and if beds count fits guests number
      const idsRoomTypes = (currentHotelValue?.roomTypes || [])
        .filter((item) => {
          return guest !== null ? item.beds === guest : true
        })
        .map((item) => item.id)

      if (!idsRoomTypes.includes(roomType.id)) {
        dispatch(changeRoomType(null))
        removeQueryParametersArray([
          HOTEL_PARAM,
          ROOM_TYPE_PARAM,
          VISIT_START_PARAM,
          VISIT_END_PARAM,
        ])
      }
    } else {
      removeQueryParametersArray([ROOM_TYPE_PARAM, VISIT_START_PARAM, VISIT_END_PARAM])
    }
  }

  const onChangeHotel = (value: IHotel | null) => {
    dispatch(changeHotel(value))
    updateRouteQuery(value?.name)
    removeQueryParametersArray([ROOM_TYPE_PARAM, VISIT_START_PARAM, VISIT_END_PARAM])
  }

  const onInitHotelValidation = (value: IHotel | null) => {
    validateHotelRoomType(value)
  }

  return {
    onChangeHotel,
    onInitHotelValidation,
  }
}
