import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { changeGuest, changeRoomType } from '@redux/actions'
import * as selectors from '@redux/selectors'
import {
  GUEST_PARAM,
  ROOM_TYPE_PARAM,
  useQueryParams,
  VISIT_END_PARAM,
  VISIT_START_PARAM,
} from './useQueryParams'

const selector = createSelector(
  selectors.getAccordion,
  selectors.getRoomType,
  (accordion, roomType) => ({
    accordion,
    roomType,
  })
)

export const useGuestsAccordion = () => {
  const dispatch = useDispatch()
  const { roomType } = useSelector(selector)
  const { addQueryParameter, removeQueryParameter, removeQueryParametersArray } = useQueryParams()

  const updateRouteQuery = (value: string | null) => {
    if (value) {
      addQueryParameter(GUEST_PARAM, value)
    } else {
      removeQueryParametersArray([GUEST_PARAM, VISIT_START_PARAM, VISIT_END_PARAM])
    }
  }

  const removeRoomType = () => {
    dispatch(changeRoomType(null))
    removeQueryParameter(ROOM_TYPE_PARAM)
  }

  const validateRoomTypeForGuest = (value: number) => {
    // remove room type selected as it changes number of beds
    if (roomType?.beds && roomType.beds !== value) {
      removeRoomType()
    }
  }

  const onChangeGuest = (value: number | null) => {
    if (value) {
      validateRoomTypeForGuest(value)
    }
    dispatch(changeGuest(value))
    updateRouteQuery(value?.toString() || null)
  }

  const onInitGuestValidation = (value: number | null) => {
    if (value) {
      validateRoomTypeForGuest(value)
    }
  }

  return {
    onChangeGuest,
    onInitGuestValidation,
  }
}
