import * as React from 'react'
import { useAppContext } from '@components/AppContext'
import { IDestination } from '@interfaces/data'
import { getLang } from '../../helpers'
import SelectButton from '../SelectButton'
import styles from './Destinations.module.scss'

interface DestinationsProps {
  destinations: IDestination[]
  onChange: (value: IDestination | null) => void
  value: IDestination | null
}

const Destinations = ({ destinations, onChange, value }: DestinationsProps) => {
  const { language } = useAppContext()

  return (
    <div className={styles.root}>
      {destinations.map((destination) => (
        <SelectButton
          className={styles.rootItem}
          heading={getLang(destination.name, language)}
          icon={null}
          key={destination.id}
          onClick={() => onChange(destination)}
          selected={value?.id ? Number(value.id) === Number(destination.id) : false}
        />
      ))}
    </div>
  )
}

export default Destinations
