import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setVisitTypeDialogData } from '@redux/actions'
import * as selectors from '@redux/selectors'
import { getLang } from '../helpers'
import { useAppContext } from './AppContext'
import Dialog from './Dialog'
import Markdown from './Markdown'

const VisitTypeDialog: React.FunctionComponent = () => {
  const { language } = useAppContext()
  const dispatch = useDispatch()
  const data = useSelector(selectors.getVisitTypeDialog)
  return (
    <Dialog
      onClose={() => dispatch(setVisitTypeDialogData(null))}
      open={Boolean(data)}
      title={getLang(data?.name, language) || ''}
    >
      <Markdown source={getLang(data?.description, language) || ''} />
    </Dialog>
  )
}

export default VisitTypeDialog
